import _objectWithoutProperties from"/app/js/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";/**
 * Text Input component.
 *
 * It normalizes value to be null on empty string and handles debounce logic.
 *
 * @copyright 2016-present, Prometheus Research, LLC
 * 
 */import*as React from"react";import*as mui from"@material-ui/core";// $FlowFixMe
import*as ReactForms from"react-forms";/**
 * Text input component.
 *
 * @public
 */export var TextInput=React.forwardRef(function(props,ref){var value=props.value,_props$Component=props.Component,Component=_props$Component===void 0?mui.Input:_props$Component,onChange=props.onChange,rest=_objectWithoutProperties(props,["value","Component","onChange"]);if(value==null){value="";}var handleOnChange=function handleOnChange(value){if(value===""||value===undefined){value=null;}onChange(value);};return React.createElement(ReactForms.Input,Object.assign({},rest,{inputRef:ref,Component:Component,value:value,onChange:handleOnChange}));});