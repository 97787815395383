import _slicedToArray from"/app/js/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";/**
 * @copyright 2019, Prometheus Research, LLC
 * 
 */import invariant from"invariant";import ResizeObserver from"resize-observer-polyfill";import*as React from"react";import*as ReactUtil from"./ReactUtil.js";import{useTheme}from"./Theme.js";import{unstable_useMediaQuery as useMediaQuery}from"@material-ui/core/useMediaQuery";export function useLayoutMode(){var theme=useTheme();var isDesktop=useMediaQuery(theme.breakpoints.up("md"));var isTablet=useMediaQuery(theme.breakpoints.between("sm","md"));if(isDesktop){return"desktop";}else if(isTablet){return"tablet";}else{return"phone";}}/**
 * Compute size of the DOM element.
 *
 * Usage:
 *
 *   let WithSize = (props) => {
 *     let [size, ref] = useDOMSize()
 *
 *     return (
 *       <div ref={ref}>
 *         {size != null ? JSON.stringify(size) : null}
 *       </div>
 *     )
 *   }
 */export function useDOMSize(){var _React$useState=React.useState(null),_React$useState2=_slicedToArray(_React$useState,2),size=_React$useState2[0],setSize=_React$useState2[1];var elementRef=React.useRef(null);var observerRef=React.useRef(null);// Recalculate dimensions on ResizeObserver callback
var onResize=function onResize(entries){var entry=entries[0];invariant(entry!=null,"useDOMSize: missing records in ResizeObserver callback");invariant(entry.target===elementRef.current,"useDOMSize: invalid target in ResizeObserver callback entries");var _entry$contentRect=entry.contentRect,width=_entry$contentRect.width,height=_entry$contentRect.height,left=_entry$contentRect.left,top=_entry$contentRect.top,bottom=_entry$contentRect.bottom,right=_entry$contentRect.right;setSize({width:width,height:height,left:left,top:top,bottom:bottom,right:right});};// Handle element mount/unmount
var onElement=React.useMemo(function(){return function(component){var element=ReactUtil.findHTMLElement(component);if(elementRef.current===element){// Skip doing work if element is the same.
return;}if(element!=null){var rect=element.getBoundingClientRect();var width=rect.width,height=rect.height,top=rect.top,right=rect.right,left=rect.left,bottom=rect.bottom;setSize({width:width,height:height,top:top,right:right,bottom:bottom,left:left});if(observerRef.current==null){observerRef.current=new ResizeObserver(onResize);}observerRef.current.observe(element);elementRef.current=element;}else{if(observerRef.current!=null&&elementRef.current!=null){observerRef.current.unobserve(elementRef.current);}elementRef.current=null;}};},[]);// Tear down ResizeObserver on unmount
React.useEffect(function(){return function cleanup(){if(observerRef.current!=null){observerRef.current.disconnect();observerRef.current=null;}};},[]);return[size,onElement];}export function WithDOMSize(Component){var displayName=ReactUtil.getComponentDisplayName(Component)||"Component";function Wrapper(props){var _useDOMSize=useDOMSize(),_useDOMSize2=_slicedToArray(_useDOMSize,2),DOMSize=_useDOMSize2[0],setElementForDOMSize=_useDOMSize2[1];return React.createElement(Component,Object.assign({},props,{DOMSize:DOMSize,setElementForDOMSize:setElementForDOMSize}));}Wrapper.displayName="WithDOMSize(".concat(displayName,")");return Wrapper;}